.Create-invoicing_root {
  .Create-invoicing_textarea {
    .theme--dark.v-label {
      top: 15px;
    }

    textarea {
      caret-color: #fff;
    }
  }

  .Create-invoicing_form {
    margin: 14px 0 0 0;
    max-width: 440px;
    width: 100%;
  }

  .Create-invoicing_input {
    position: relative;
    margin-bottom: 14px;

    .v-menu__content {
      max-width: 100%;
      right: 0;
    }

    label {
      font-size: 14px;
    }

    .v-select__selection {
      font-size: 14px;
    }

    .theme--dark.v-label {
      color: rgba(255, 255, 255, 0.44);
    }

    &Margin {
      margin-bottom: 20px;
    }

    .select-wrapper {
      top: 26px;

      label {
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        color: #fff;
        margin-bottom: 10px;
        display: flex;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
      /* <-- Apparently some margin are still there even though it's hidden */
    }

    .Create-invoicing_container {
      position: relative;

      input {
        font-size: 14px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.44);
          line-height: 20px;
          font-size: 14px;
        }
      }
    }

    .Create-invoicing_label {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: left;
      color: #fff;
      margin-bottom: 10px;
      display: flex;

      .Exchange_limit {
        color: #2af3f3;
      }
    }

    .Exchange_input {
      display: block;
      height: 46px;
      border-radius: 4px;
      background: rgba(#fff, 0.04);
      border: none;
      outline: none;
      padding: 0 12px;
      width: 100%;
      color: #fff;
      font-size: 12px;
    }

    .multiselect {
      position: absolute;
      right: 10px;
      top: 0;
      width: 50px;

      .multiselect__select {
        padding: 0;
        margin: 0;
        width: auto;
        height: 100%;

        &:before {
          top: 60%;
        }
      }

      .multiselect__tags {
        border: none;
        background: transparent;
        padding: 0;
      }

      .multiselect__single {
        line-height: 46px;
        margin: 0;
        background: transparent;
        color: #fff;
      }

      .multiselect__content-wrapper {
        width: 60px;
        z-index: 5;

        .multiselect__option {
          padding: 5px 12px;
          min-height: 26px;
          background: rgba(42, 243, 243, 0.2);

          &--highlight {
            background: #102032;
          }

          &--selected {
            background: #50c5e9;
          }
        }
      }
    }
  }
}